import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-conversations.reducers';
import { GetConversationsEffects } from './store/get-conversations.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ GetConversationsEffects ]),
    StoreModule.forFeature('getConversations', reducer),
  ],
})
export class GetConversationsModule {}
