import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  getConversationsAction,
  getConversationsFailureAction,
  getConversationsSuccessAction,
} from './get-conversations.actions';
import { GetConversationsService } from '../get-conversations.service';

@Injectable()
export class GetConversationsEffects {
  getConversations$ = createEffect(() => this.actions$.pipe(
    ofType(getConversationsAction),
    switchMap(action =>
      this.getConversationsService.get().pipe(
        map(conversations => getConversationsSuccessAction({ conversations })),
        catchError(error => of(getConversationsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getConversationsService: GetConversationsService,
  ) {
  }
}
