import { Action, createReducer, on } from '@ngrx/store';
import {
  getSignedUrlAction, getSignedUrlSuccessAction, getSignedUrlFailureAction,
} from './get-signed-url.actions';
import { GetSignedUrlState } from './get-signed-url.types';

const initialState = {
  loading: false,
  loaded: false,
  url: null,
};

const getSignedUrlReducer = createReducer<GetSignedUrlState>(
  initialState,
  on(
    getSignedUrlAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      url: null,
      error: null,
    })
  ),
  on(
    getSignedUrlSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      url: action.url,
      error: null,
    })
  ),
  on(
    getSignedUrlFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetSignedUrlState, action: Action) => getSignedUrlReducer(state, action);
