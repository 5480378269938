import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { sendMessageAction, sendMessageFailureAction, sendMessageSuccessAction, } from './send-message.actions';
import { SendMessageService } from '../send-message.service';

@Injectable()
export class SendMessageEffects {
  sendMessage$ = createEffect(() => this.actions$.pipe(
    ofType(sendMessageAction),
    switchMap(action =>
      this.sendMessageService.send(action.message).pipe(
        map(data => sendMessageSuccessAction({ data })),
        catchError(error => of(sendMessageFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private sendMessageService: SendMessageService,
  ) {
  }
}
