import { Action, createReducer, on } from '@ngrx/store';
import {
  sendMessageAction, sendMessageSuccessAction, sendMessageFailureAction,
} from './send-message.actions';
import { SendMessageState } from './send-message.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const sendMessageReducer = createReducer<SendMessageState>(
  initialState,
  on(
    sendMessageAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    sendMessageSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    sendMessageFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: SendMessageState, action: Action) => sendMessageReducer(state, action);
