import { Action, createReducer, on } from '@ngrx/store';
import {
  getConversationsAction,
  getConversationsSuccessAction,
  getConversationsFailureAction,
  addConversationAction,
  updateConversationAction,
} from './get-conversations.actions';
import { GetConversationsState } from './get-conversations.types';

const initialState = {
  loading: false,
  loaded: false,
  conversations: null,
  error: null,
};

const getConversationsReducer = createReducer<GetConversationsState>(
  initialState,
  on(
    getConversationsAction,
    state => ({
      ...state,
      loading: true,
    })
  ),
  on(
    getConversationsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      conversations: action.conversations,
      error: null,
    })
  ),
  on(
    getConversationsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
  on(
    addConversationAction,
    (state, action) => ({
      ...state,
      conversations: [...(state.conversations || []), action.conversation],
    })
  ),
  on(
    updateConversationAction,
    (state, action) => ({
      ...state,
      conversations: (state.conversations || []).map(conversation => {
        if (conversation.id === action.conversationId) {
          return {
            ...conversation,
            ...action.payload,
          }
        }
        return conversation;
      }),
    })
  ),
);

export const reducer = (state: GetConversationsState, action: Action) => getConversationsReducer(state, action);
