import { Injectable } from '@angular/core';
import { defer, from, Observable, of } from 'rxjs';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { map } from 'rxjs/operators';
import { Message } from '../get-messages/get-messages.service';

export interface MessagePayload {
  conversationId?: string,
  jobTitle: string,
  userIds: string[],
  subject: string,
  title: string,
  message: string,
  attach?: {
    fileName: string,
    displayName: string,
  },
}

@Injectable({
  providedIn: 'root',
})
export class SendMessageService {
  constructor(private functions: Functions) {
  }

  send(message: MessagePayload): Observable<any> {
    return defer(() =>
      httpsCallable<MessagePayload, Message>(this.functions, 'sendMessage')(message)
    ).pipe(
      map(response => response.data),
    );
  }
}
