import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { map } from 'rxjs/operators';

export type GetSignedUrlParams = ({
  fileName?: string,
} | {
  fullPath?: string,
}) & {
  term?: { value: number, type: 'y' }
};

@Injectable({
  providedIn: 'root',
})
export class GetSignedUrlService {
  constructor(private functions: Functions) {
  }

  get(params: GetSignedUrlParams): Observable<string> {
    return defer(() =>
      httpsCallable<GetSignedUrlParams, { url: string[] }>(this.functions, 'getCompanySignedUrl')(params)
    ).pipe(
      map(response => {
        const [ url ] = response.data.url;
        if (!url) throw new Error('Error: missed url');
        return url;
      }),
    );
  }
}
