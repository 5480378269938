import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/send-message.reducers';
import { SendMessageEffects } from './store/send-message.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ SendMessageEffects ]),
    StoreModule.forFeature('sendMessage', reducer),
  ],
})
export class SendMessageModule {}
