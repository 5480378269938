import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-signed-url.reducers';
import { GetSignedUrlEffects } from './store/get-signed-url.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ GetSignedUrlEffects ]),
    StoreModule.forFeature('getSignedUrl', reducer),
  ],
})
export class GetSignedUrlModule {
}
