import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getSignedUrlAction, getSignedUrlFailureAction, getSignedUrlSuccessAction } from './get-signed-url.actions';
import { GetSignedUrlService } from '../get-signed-url.service';

@Injectable()
export class GetSignedUrlEffects {
  getSignedUrl$ = createEffect(() => this.actions$.pipe(
    ofType(getSignedUrlAction),
    switchMap(action =>
      this.getSignedUrlService.get(action.payload).pipe(
        map(url => getSignedUrlSuccessAction({ url })),
        catchError(error => of(getSignedUrlFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getSignedUrlService: GetSignedUrlService,
  ) {
  }
}
