import { collection, collectionData, CollectionReference, Firestore, query, where } from '@angular/fire/firestore';
import { Conversation } from '../messages/get-conversations/get-conversations.service';
import { map } from 'rxjs/operators';
import { Message } from '../messages/get-messages/get-messages.service';

export const hasNewMassages = (userId: string, conversations: Conversation[]) => {
  return conversations.some(conversation => conversation.notification && conversation.notification[userId]);
}

// Todo: Add security (+)
export const getConversations$ = (firestore: Firestore, userId: string, companyId: string) => {
  const conversationsRef = collection(firestore, 'conversations') as CollectionReference<Conversation>;
  return collectionData<Conversation>(query(conversationsRef, where('accountId', '==', companyId)), {
    idField: 'id',
  }).pipe(
    map(conversationsData => conversationsData.filter((conversation: Conversation) =>
      (conversation.createdById === userId || conversation.userIds.includes(userId))
    ))
  );
};

// Todo: Add security (+)
export const getMessages$ = (firestore: Firestore, conversationId: string) => {
  const messagesRef = collection(firestore, `conversations/${ conversationId }/messages`) as CollectionReference<Message>;
  return collectionData<Message>(messagesRef, {
    idField: 'id',
  });
}
