import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUser } from '../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { Firestore } from '@angular/fire/firestore';
import { getConversations$ } from '../../data-access/conversation';
import { UserService } from '../../auth/services/user.service';

export interface Conversation {
  id: string,
  subject: string,
  jobTitle: string,
  userIds: string[],
  createdById: string,
  createdAt: number,
  updatedAt: number,
  hidden?: boolean,
  notification?: { [uid: string]: boolean },
}

@Injectable({
  providedIn: 'root',
})
export class GetConversationsService {
  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<Conversation[]> {
    return this.store$.pipe(
      select(selectCurrentUser),
      filter(inputIsNotNullOrUndefined),
      switchMap(currentUser => {
        return getConversations$(this.firestore, currentUser.id, currentUser.accountId);
      }),
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
