import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GetConversationsState } from './get-conversations.types';
import { Conversation } from '../get-conversations.service';

const getConversationsFeatureSelector = createFeatureSelector<GetConversationsState>('getConversations');

export const selectGetConversationsLoading = createSelector(
  getConversationsFeatureSelector,
  state => state.loading
);

export const selectGetConversationsLoaded = createSelector(
  getConversationsFeatureSelector,
  state => state.loaded
);

export const selectGetConversationsData = createSelector(
  getConversationsFeatureSelector,
  state => state.conversations
);

export const selectGetConversationForId = (id: string) => createSelector(
  getConversationsFeatureSelector,
  state => state.conversations?.find(c => c.id === id),
);

export const selectGetConversationsError = createSelector(
  getConversationsFeatureSelector,
  state => state.error
);

export const selectGetConversationNotification = (conversationId: string, userId: string) => createSelector(
  getConversationsFeatureSelector,
  state => {
    const foundConversation: Conversation | undefined = state.conversations?.find(
      (conversation: Conversation) => conversation.id === conversationId
    );
    const notification = foundConversation?.notification;
    return notification ? notification[userId] : false;
  }
);
